import React, { useContext } from 'react';
import { PAGE_INDICES } from '../constants/routing';
import { TravelInsureContext } from '../context/TravelInsureContext';
import SchemeDetailsPage from './schemeDetailsPage/SchemeDetails';
import ContributionDetailsPage from './ContributionDetailsPage/ContributionDetails';
import BeneficiariesPage from './beneficiariesPage/BeneficiariesPage';
import BeneficiariesSplitPage from './beneficiariesSplitPage/BeneficiariesSplitPage';
import ReviewPage from './reviewPage/ReviewPage';
import SuccessPage from './successPage/successPage';
import QuotationDetails from './quotationPage/QuotationDetails'

const renderComponent = (action) => {
    switch (action) {
    case PAGE_INDICES.QUOTATION:
        return <QuotationDetails />;
    case PAGE_INDICES.PERSONAL_DETAILS:
        return <SchemeDetailsPage />;
    case PAGE_INDICES.BENEFICIARIES:
        return <BeneficiariesPage />;
    case PAGE_INDICES.BENEFICIARIES_SPLIT:
        return <BeneficiariesSplitPage />;
    case PAGE_INDICES.REVIEW_APPLICATION:
        return <ReviewPage />;
    case PAGE_INDICES.SUCCESS:
        return <SuccessPage />;
    default:
        return null;
    }
};

const Outlet = () => {
    const { t_activePageIndex } = useContext(TravelInsureContext);
    return <>{renderComponent(t_activePageIndex)}</>;
};

export default Outlet;
