import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import {
    OmApplicationWizardHeader,
    OmApplicationProgressBar,
    OmIcon,
    Om1ColLayout,
} from '@om/component-library-react';
import OmButton from '../../../../../components/forms/OmButton';
import { TravelInsureContext } from './context/TravelInsureContext';
import { PAGE_INDICES, PROGRESS_BAR_STEPS } from './constants/routing';
import { removeLocalStorage } from '../../utils/travelsure-utils/util';

const ApplicationWizardHeader = ({ callMeBack }) => {
    const { t_activePageIndex } = useContext(TravelInsureContext);
    const closeHander = () => {
        let check  = confirm('Are you sure you want to close this page?')
        if(check){
            if (typeof window !== 'undefined') {
                localStorage.clear();
            }
            navigate('/personal');
        }
    };

    return (
        <OmApplicationWizardHeader
            helpNumber="0307 000 600"
            className="banner-image"
        >
            <div slot="wizard-title">
                <p>
                    {Object.keys(PAGE_INDICES)
                        [t_activePageIndex]?.toString()
                        .replace('_', ' ')
                        .toUpperCase()}
                </p>
                <h5>
                    <strong>Travel Insurance</strong>
                </h5>
            </div>

            <span slot="contact-button">
                <OmButton
                    type="secondary"
                    actionType="Call Me Back"
                    size="small"
                    text="Call me back"
                    callMeBackObject={callMeBack ? JSON.stringify(callMeBack) : ''}
                />
            </span>
            <a slot="close-button" className='joureyCloseNameContainer'>
                <span className='joureyCloseName' onClick={() => closeHander()}>Close</span>
                <OmIcon
                    className="close-icon"
                    iconName="close"
                    onClick={() => closeHander()}
                /> 
            </a>

            <div slot="progress-bar">
                <Om1ColLayout className="header">
                    <OmApplicationProgressBar
                        applicationName="Travel Insurance"
                        closable
                        hideDesktop={t_activePageIndex === PAGE_INDICES.SUCCESS}
                        // hideMobile={t_activePageIndex === PAGE_INDICES.SUCCESS}
                        className="banner-image"
                        steps={PROGRESS_BAR_STEPS}
                        index={
                            t_activePageIndex === PAGE_INDICES.BENEFICIARIES_SPLIT
                                ? PAGE_INDICES.BENEFICIARIES
                                : t_activePageIndex
                        }
                    >
                        <OmButton
                            actionType="url"
                            slot="close-application"
                            type="text"
                            size="small"
                            // url="/"
                            onClick={() => closeHander()}
                            text="CLOSE"
                        />
                    </OmApplicationProgressBar>
                </Om1ColLayout>
            </div>
        </OmApplicationWizardHeader>
    );
};

export default ApplicationWizardHeader;
