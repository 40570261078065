import React, {useContext} from 'react';
import { TravelInsureContext } from '../../context/TravelInsureContext';
import { OmdsDatePickerField } from '@mom-omds/react-design-system-components/dist/generated/components';
import { OmFormDropdownFieldWrapper } from '@om/component-library-react';
import { isDateInPast, isDateInPastQuote, isInputValid,travelInsurePremium } from '../../../../../ghana/utils/travelsure-utils/util';
import { OmApplicationSummaryDetailsCard } from '@om/component-library-react';
import './Quotation.scss';
import OmButton from '../../../../../../../components/forms/OmButton';
const referenceNationalities = require('../../constants/refeferenceData/countries');

const QuotationDetails = () => {

    const { quotationData,updateMVestData } = useContext(TravelInsureContext);

    const handleChange = (e) => {
        if (e.target.placeholder === 'Destination') {
            updateMVestData({ 'destination': e.detail.value }, 'schemeDetails');
            // updateMVestData({ 'travel_cost': travelInsurePremium(quotationData?.schemeDetails?.date_of_depature, quotationData?.schemeDetails?.date_of_return) }, 'schemeDetails');
            //console.log("TRAVEL_INDEX::", travelInsurePremium(quotationData?.schemeDetails?.date_of_depature, quotationData?.schemeDetails?.date_of_return))
        }else if (e.target.placeholder === 'e.g. 1980-05-05') {
            if(!isDateInPast(e.detail)){
                updateMVestData({ 'date_of_depature': e.detail }, 'schemeDetails')
                updateMVestData({ 'travel_cost': 0 }, 'schemeDetails')
                // setTravelQuote()
            }else{
                updateMVestData({ 'date_of_depature': null }, 'schemeDetails')
                updateMVestData({ 'travel_cost': 0 }, 'schemeDetails')
            }
            // updateMVestData({ "travel_cost": travelInsurePremium(quotationData?.schemeDetails?.date_of_depature, quotationData?.schemeDetails?.date_of_return) }, "schemeDetails");
        }else if (e.target.placeholder === 'e.g. 2024-05-06') {
            if(!isDateInPastQuote(quotationData?.schemeDetails?.date_of_depature,e.detail)){
                updateMVestData({ 'date_of_return': e.detail }, 'schemeDetails')
                updateMVestData({ 'travel_cost': 0 }, 'schemeDetails')
                // setTravelQuote()
            }else{
                updateMVestData({ 'date_of_return': null }, 'schemeDetails')
                updateMVestData({ 'travel_cost': 0 }, 'schemeDetails')
            }
            // updateMVestData({ "travel_cost": travelInsurePremium(quotationData?.schemeDetails?.date_of_depature, quotationData?.schemeDetails?.date_of_return) }, "schemeDetails");
        }
    }

    const setTravelQuote = () => {
        if(quotationData?.schemeDetails?.date_of_depature !== '' && 
            quotationData?.schemeDetails?.date_of_return !== '' && 
            quotationData?.schemeDetails?.destination !== ''){
            updateMVestData({ 'travel_cost': travelInsurePremium(quotationData?.schemeDetails?.date_of_depature, quotationData?.schemeDetails?.date_of_return)}, 'schemeDetails');
        }
    }

    return (
        <div className="mvest-scheme-details-page travel-quote-main" id='travel_quotation'>
            <div className="mvest-scheme-details-page-wrapper">
                <h5 className="heading medium" id="heading-anchor">Get your travel insurance quotation</h5>
                <p>Let’s start off with a few simple questions about your trip to help us with the calculation of your premium.</p>

                <OmdsDatePickerField
                    label="Date of departure"
                    value={quotationData?.schemeDetails?.date_of_depature}
                    // hasError={isInputValid(quotationData?.schemeDetails?.date_of_depature) === "error"}
                    onPersist={(e) => handleChange(e)}
                    //   onPersist={(e) => updateMVestData({ date_of_depature: e.detail }, "schemeDetails") }
                    placeholder="e.g. 1980-05-05">
                    {isInputValid(quotationData?.schemeDetails?.date_of_depature) === 'error' ? 
                        (<span slot="hint-text">{"Please select your departure date from calender and it can't be a past date*"}</span> ) : ''}
                </OmdsDatePickerField>

                <OmdsDatePickerField
                    label="Date of return"
                    value={quotationData?.schemeDetails?.date_of_return}
                    //   hasError={isInputValid(quotationData?.schemeDetails?.date_of_return) === "error"}
                    onPersist={(e) => handleChange(e)}
                    //   onPersist={(e) => updateMVestData({ date_of_return: e.detail }, "schemeDetails") }
                    placeholder="e.g. 2024-05-06">
                    {isInputValid(quotationData?.schemeDetails?.date_of_return) === 'error' ? 
                        (<span slot="hint-text">{"Please select your return date from calender and it can't be a past date*"}</span> ) : '' }
                </OmdsDatePickerField>

                <OmFormDropdownFieldWrapper
                    id="destination"
                    name="destination"
                    useNativeMobileDropdown
                    required
                    size="full"
                    formId="schemeDetailsForm"
                    placeholder="Destination"
                    align="left"
                    type="text"
                    value={quotationData?.schemeDetails?.destination}
                    state={isInputValid(quotationData?.schemeDetails?.destination)}
                    errorMessage={ isInputValid(quotationData?.schemeDetails?.destination) === 'error' ? 'Please enter your destination' : undefined }
                    options={
                        referenceNationalities &&
                      JSON.stringify(
                          referenceNationalities.COUNTRIES.map((option) => ({
                              value: option.fullcode,
                              label: option.description,
                          }))
                      )
                    }
                    onOnSelect={e => handleChange(e)}
                />

                {/* <OmApplicationSummaryDetailsCard style={{borderTop:'2px solid #4db152'}}>
                    <div slot="content" style={{display: 'flex', justifyContent: 'space-around'}}>
                        <div className="">
                            <h5><strong>QUOTE SUMMARY</strong></h5>
                            <p>Your total travel cover will cost</p>
                        </div>
                        <div className="">
                            <h3>GH₵ {quotationData?.schemeDetails?.travel_cost}</h3>
                        </div>
                    </div>
                </OmApplicationSummaryDetailsCard> */}
                <div>
                    <div className='quote-wrapper'>
                        {quotationData?.schemeDetails?.travel_cost > 0 ?
                            <div className='quote-header'>
                                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                    <div>
                                        <h5 style={{paddingTop: '7px'}}><strong>QUOTE SUMMARY</strong></h5>
                                        <p>Your total travel cover will cost</p>
                                    </div>
                                    <div>
                                        <h3>GH₵ {quotationData?.schemeDetails?.travel_cost}</h3>
                                    </div>
                                </div>
                            </div>
                            :
                            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                <div>
                                    <p>Calculate your total travel cover</p>
                                </div>
                                <div className='quote-summary-right'>
                                    <OmButton
                                        text="CALCULATE QUOTE"
                                        iconName="arrow_forward"
                                        slot="right"
                                        onClick={() => setTravelQuote()}
                                        type="primary"
                                        size="small"
                                        disabled={!(isInputValid(quotationData?.schemeDetails?.date_of_return) && isInputValid(quotationData?.schemeDetails?.date_of_depature) && isInputValid(quotationData?.schemeDetails?.destination))}
                                    />
                                </div>
                            </div>
                        }
                        
                        {/* <hr style={{width:'100%'}}/> */}
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
export default QuotationDetails;