import React, { createContext, useState } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

const TravelInsureContext = createContext({
    // formData getters
    quotationData: {
        schemeDetails: {
            passport_number: undefined,
            first_name: undefined,
            last_name: undefined,
            birth_date: undefined,
            // date_of_depature: new Date().toLocaleDateString("en-GB"),
            date_of_depature: undefined,
            date_of_return: undefined,
            nationality: 'Ghana',
            destination: undefined,
            travel_cost: 0,
            gender: undefined,
            marital_status: undefined,
            occupation: undefined,
            mobile_number: undefined,
            postal_address: undefined,
            live_in_ghana: undefined,
            politically_exposed: undefined,
            title: undefined,
            email_address: undefined,
            loading: false,
            momo_number: undefined,
            paymentModal: false,
            agentCode: undefined,
        },
        
    },
    // memberFiles: [],
    beneficiaryListDataTravel: [],
    idToRemove: 0,
    idToEdit: 0,
    beneficiaryId: 0,
    hasAcceptedTermsAndCondition: false,
    // formData setters
    updateMVestData: () => {},
    addBeneficiaryToListData: () => {},
    updatebeneficiaryListDataTravel: () => {},
    deleteBeneficiary: () => {},
    getBeneficiaryToEdit: () => {},
    setBeneficiaryId: () => {},
    setRemoveId: () => {},
    setEditId: () => {},
    updateObjectInArray: () => {},
    handleFileInput: () => {},
    removeFile:() => {},

    // app state
    t_activePageIndex: 0,
    navigatePrevious: () => {},
    navigateContinue: () => {},
    navigateTo: () => {},
    togglePaymentModal: () => {},

    // drawer
    isDrawerOpen: false,
    isEditDrawerOpen: false,
    isModalOpen: false,
    isEvenSplit: false,
    paymentModal: false,
    splitEvenly: () => {},
    toggleDrawer: () => {},
    toggleEditDrawer: () => {},
    toggleMaxBeneficiariesModal: () => {},
    acceptTermsAndCondition: () => {},
});

const TravelInsureProvider = ({ children }) => {
    // const [t_t_activePageIndex, sett_t_activePageIndex] = useLocalStorage(
    //   "t_t_activePageIndex",
    //   0
    // );
    const [t_activePageIndex, setActivePageIndex] = useLocalStorage('t_activePageIndex', 0);

    const [quotationData, setQuotation] = useLocalStorage('quotationData', {
        schemeDetails: {
            passport_number: undefined,
            first_name: undefined,
            last_name: undefined,
            birth_date: undefined,
            date_of_depature: undefined,
            date_of_return: undefined,
            nationality: 'Ghana',
            destination: undefined,
            travel_cost: 0,
            gender: undefined,
            marital_status: undefined,
            occupation: undefined,
            mobile_number: undefined,
            postal_address: undefined,
            live_in_ghana: undefined,
            politically_exposed: undefined,
            title: undefined,
            // id_number: "",
            email_address: undefined,
            loading: false,
            momo_number: undefined,
            paymentModal: false,
            agentCode: undefined,
        // tin: "",
        }
    });
    const [beneficiaryListDataTravel, setbeneficiaryListDataTravel] = useLocalStorage('beneficiaryListDataTravel', []);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEvenSplit, setIsEvenSplit] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [beneficiaryId, setId] = useState(0);
    const [idToRemove, setIdToRemove] = useState(0);
    const [idToEdit, setIdToEdit] = useState(0);
    const [memberSignatureFilename, setMemberSignatureFilename] = useLocalStorage('memberSignatureFilename','');
    const [memberIdCardFilename, setMemberIdCardFilename] = useLocalStorage('memberIdCardFilename','');
    const [ memberIdCard, setMemberIdCard] = useLocalStorage('memberIdCard',null);
    const [ memberSignature, setMemberSignature] = useLocalStorage('memberSignature',null);
    const [hasAcceptedTermsAndCondition, setHasAcceptedTermsAndCondition] = useLocalStorage('hasAcceptedTermsAndCondition', false);
    const navigatePrevious = () => { setActivePageIndex((prevState) => prevState - 1); };
    const navigateContinue = () => { setActivePageIndex((prevState) => prevState + 1); };
    const navigateTo = (page) => { setActivePageIndex(page); };
    const toggleDrawer = () => { setIsDrawerOpen((prevState) => !prevState); };
    const splitEvenly = () => { setIsEvenSplit((prevState) => !prevState); };
    const toggleEditDrawer = () => { setIsEditDrawerOpen((prevState) => !prevState); };
    const togglePaymentModal = () => { setPaymentModal((prevState) => !prevState); };
    const toggleMaxBeneficiariesModal = () => { setIsModalOpen((prevState) => !prevState); };

    const acceptTermsAndCondition = () => { setHasAcceptedTermsAndCondition((prevState) => !prevState); };
    const updateMVestData = (obj, page) => {
        setQuotation((prevData) => ({
            ...prevData,
            [page]: { ...prevData[page], ...obj },
        }));
    };

    const addBeneficiaryToListData = (obj) => { setbeneficiaryListDataTravel((prevArray) => [...prevArray, obj]); };

    const updatebeneficiaryListDataTravel = (arr) => { setbeneficiaryListDataTravel(arr); };

    const updateObjectInArray = (arr, { id, ...updatedObj }) => {
        const index = arr.findIndex((obj) => obj.id === id);
        if (index === -1) {
            setbeneficiaryListDataTravel(arr);
        }
        const newArr = [...arr];
        newArr[index] = {
            ...newArr[index],
            ...updatedObj,
        };
        setbeneficiaryListDataTravel(newArr);
    };

    const deleteBeneficiary = (id) => {
        setbeneficiaryListDataTravel((prevArray) =>
            prevArray.filter((obj) => obj.id !== id)
        );
    };
    const getBeneficiaryToEdit = () => {
        return beneficiaryListDataTravel[
            beneficiaryListDataTravel.findIndex((obj) => obj.id === idToEdit)
        ];
    };
    const setBeneficiaryId = () => { setId((prevId) => prevId + 1); };

    const setRemoveId = (idToRemove) => { setIdToRemove(idToRemove); };

    const setEditId = (idToEdit) => { setIdToEdit(idToEdit); };

    const handleFileInput = (event, inputType) => {
        const file = event?.target?.files[0];
        if (file) {
            const filename = file?.name;
            // Encode the file data as a base64 string
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const fileData = reader.result;
                const fileSize = file.size;
                // Now, you can store the file content (string) in localStorage using your custom hook.
                if (inputType === 'memberSignature') {
                    setMemberSignatureFilename(filename);
                    setMemberSignature({data:fileData, size: fileSize});
                } else if (inputType === 'memberIdCard') {
                    setMemberIdCardFilename(filename);
                    setMemberIdCard({data:fileData, size: fileSize});
                }
            };
        }
    };

    const removeFile = (inputType) => {
        if (inputType === 'memberSignature') {
            setMemberSignatureFilename('');
            setMemberSignature(null);
        } else if (inputType === 'memberIdCard') {
            setMemberIdCardFilename('');
            setMemberIdCard(null);
        }
    };

    return (
        <TravelInsureContext.Provider
            value={{
                quotationData,
                t_activePageIndex,
                isDrawerOpen,
                beneficiaryListDataTravel,
                beneficiaryId,
                isModalOpen,
                isEditDrawerOpen,
                idToRemove,
                idToEdit,
                hasAcceptedTermsAndCondition,
                memberSignatureFilename,
                memberIdCardFilename,
                memberSignature,
                memberIdCard,
                paymentModal,
                updateMVestData,
                navigateContinue,
                navigatePrevious,
                navigateTo,
                toggleDrawer,
                addBeneficiaryToListData,
                updatebeneficiaryListDataTravel,
                deleteBeneficiary,
                setRemoveId,
                setEditId,
                toggleMaxBeneficiariesModal,
                setBeneficiaryId,
                toggleEditDrawer,
                getBeneficiaryToEdit,
                updateObjectInArray,
                splitEvenly,
                acceptTermsAndCondition,
                handleFileInput,
                removeFile,
                togglePaymentModal
            }}
        >
            {children}
        </TravelInsureContext.Provider>
    );
};

export { TravelInsureContext, TravelInsureProvider };
