import React from 'react';
import './SchemeDetailsPage.scss';
import SchemeDetailsSection from './sections/SchemeDetailsSections';

const SchemeDetailsPage = () => {

    return (
        <div className="mvest-scheme-details-page">
            <div className="mvest-scheme-details-page-wrapper">
                <h5 className="heading medium" id="heading-anchor">Let’s get to know you a little better</h5>
                <SchemeDetailsSection />
            </div>
        </div>
    );
};

export default SchemeDetailsPage;

