import React, { useContext, useState, useEffect } from 'react';
import { DEFAULT_VALIDATION_DELAY } from '../../../../../ghana/configs/travelsure-configs/config';
import {
    OmFormInputFieldWrapper,
    OmFormDropdownFieldWrapper,
    OmApplicationBeneficiaryDetails,
    OmSideDrawer,
} from '@om/component-library-react';
import OmButton from '../../../../../../../components/forms/OmButton';
import { TravelInsureContext } from '../../context/TravelInsureContext';
import { OmdsDatePickerField } from '@mom-omds/react-design-system-components/dist/generated/components';
import { isInputValid,  areAllInputsInvalid, isUserAbove15YearsOfBirth, getAgeValue } from '../../../../../ghana/utils/travelsure-utils/util';
const referenceRelationship = require('../../constants/refeferenceData/relationships');

const EditBeneficiariesDetailsSideDrawer = () => {
    const {
        isEditDrawerOpen,
        beneficiaryListDataTravel,
        toggleEditDrawer,
        getBeneficiaryToEdit,
        updateObjectInArray,
        idToEdit,
    } = useContext(TravelInsureContext);
  
    const [beneficiary, setBeneficiary] = useState({
        firstname: getBeneficiaryToEdit()?.firstname,
        OtherName: getBeneficiaryToEdit()?.OtherName,
        Relationship: getBeneficiaryToEdit()?.Relationship,
        birth_date: getBeneficiaryToEdit()?.birth_date,
        trustee_firstname: getBeneficiaryToEdit()?.trustee_firstname,
        trustee_OtherName: getBeneficiaryToEdit()?.trustee_OtherName,
        trustee_MobileNumber: getBeneficiaryToEdit()?.trustee_MobileNumber,
        trustee_email: getBeneficiaryToEdit()?.trustee_email,
    });


    const cancelAndCloseEditing = () => { toggleEditDrawer(); };
    const [showTrustee, setShowTrustee] = useState(false);

    useEffect(()=>{
        if (beneficiary?.trustee_firstname != null){ setShowTrustee(!showTrustee) }
    },[])


    const handleChange = (e) => {
        const value = e.detail;
        const input = e.target.name;
        let formattedValue = '';
        if (input === 'MobileNumber' || input == 'trustee_MobileNumber') {
            formattedValue = value.startsWith('+233') ? value : '+233' + value;;
        } else if (input === 'birth_date') {
            setBeneficiary({...beneficiary, birth_date: e.detail})
            if(getAgeValue(value) < 15){
                setShowTrustee(true)
            }else{
                setShowTrustee(false)
            }
            return;
        }else {
            formattedValue = value;
        }
        setBeneficiary({
            ...beneficiary,
            [input]: formattedValue,
        });
    };

    const getDataToCheck = () => {
        return {
            firstname: beneficiary?.firstname,
            OtherName: beneficiary?.OtherName,
            Relationship: beneficiary?.Relationship,
            birth_date: beneficiary?.birth_date,
        };
    }

    const id = idToEdit
    const saveBeneficiary = () => {
        updateObjectInArray(beneficiaryListDataTravel, { id, ...beneficiary });
        toggleEditDrawer();
    };

    return (
        <OmSideDrawer
            onOnSideDrawerClosed={() => {
                toggleEditDrawer();
            }}
            open={isEditDrawerOpen}
        >
            <div slot="side-drawer-content" className='travel-quote-main'>
                <OmApplicationBeneficiaryDetails>
                    <div slot="om-application-beneficiary-details">
                        <div>
                            <h5
                                className="no-top-margin side-drawer-header"
                                id="beneficiaries-side-drawer"
                            >
                                <strong>Edit beneficiary details </strong>
                            </h5>

                            <OmFormInputFieldWrapper
                                label="First name"
                                id="firstname"
                                name="firstname"
                                isSensitive
                                labelAlwaysShow={true}
                                required
                                formId="beneficiaryDetailsForm"
                                size="full"
                                align="left"
                                type="text"
                                placeholder="e.g. John"
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                value={beneficiary?.firstname}
                                state={isInputValid(beneficiary?.firstname)}
                                errorMessage={
                                    isInputValid(beneficiary?.firstname) === 'error'
                                        ? 'Please enter first name'
                                        : undefined
                                }
                                onPersist={(e) => handleChange(e)}
                            />
                            <OmFormInputFieldWrapper
                                label="Last name"
                                id="OtherName"
                                name="OtherName"
                                isSensitive
                                labelAlwaysShow={true}
                                required
                                formId="beneficiaryDetailsForm"
                                size="full"
                                align="left"
                                type="text"
                                placeholder="e.g. Doe"
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                value={beneficiary?.OtherName}
                                state={isInputValid(beneficiary?.OtherName)}
                                errorMessage={
                                    isInputValid(beneficiary?.OtherName) === 'error'
                                        ? 'Please enter last name'
                                        : undefined
                                }
                                onPersist={(e) => handleChange(e)}
                            />
                            <OmFormDropdownFieldWrapper
                                id="Relationship"
                                name="Relationship"
                                useNativeMobileDropdown
                                required
                                size="full"
                                formId="beneficiaryDetailsForm"
                                placeholder="Relationship"
                                align="left"
                                type="text"
                                value={beneficiary?.Relationship}
                                state={isInputValid(beneficiary?.Relationship)}
                                errorMessage={
                                    isInputValid(beneficiary?.Relationship) === 'error'
                                        ? 'Please select relationship'
                                        : undefined
                                }
                                options={
                                    referenceRelationship &&
                      JSON.stringify(
                          referenceRelationship.RELATIONSHIPS.map((option) => ({
                              value: option.fullcode,
                              label: option.description,
                          }))
                      )
                                }
                                onOnSelect={(e) =>
                                    setBeneficiary({
                                        ...beneficiary,
                                        Relationship: e.detail.value,
                                    })
                                }
                            />
                            <OmdsDatePickerField
                                label="Date of birth"
                                name='birth_date'
                                value={beneficiary?.birth_date}
                                hasError={isInputValid(beneficiary?.birth_date) === 'error'}
                                onPersist={(e) => handleChange(e)}
                                // onPersist={ (e) => setBeneficiary({...beneficiary, birth_date: e.detail,}) }
                                placeholder="e.g. 1980-05-05"
                            >
                                {isInputValid(beneficiary?.birth_date) === 'error' ? (<span slot="hint-text">{'Please select date of birth from calender'}</span>) : undefined}
                            </OmdsDatePickerField>

             
                            {showTrustee ?
                                <div className="trustee_container">
                                    <h6><b>Add a trustee</b></h6>
                                    <OmFormInputFieldWrapper
                                        label="First name"
                                        id="trustee_firstname"
                                        name="trustee_firstname"
                                        isSensitive
                                        labelAlwaysShow={true}
                                        required
                                        formId="beneficiaryDetailsForm"
                                        size="full"
                                        align="left"
                                        type="text"
                                        placeholder="e.g. John"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={beneficiary?.trustee_firstname}
                                        state={isInputValid(beneficiary?.trustee_firstname)}
                                        errorMessage={ isInputValid(beneficiary?.trustee_firstname) === 'error' ? 'Please enter first name' : undefined }
                                        onPersist={(e) => handleChange(e)}
                                    />
                                    <OmFormInputFieldWrapper
                                        label="Last name"
                                        id="trustee_OtherName"
                                        name="trustee_OtherName"
                                        isSensitive
                                        labelAlwaysShow={true}
                                        required
                                        formId="beneficiaryDetailsForm"
                                        size="full"
                                        align="left"
                                        type="text"
                                        placeholder="e.g. Doe"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={beneficiary?.trustee_OtherName}
                                        state={isInputValid(beneficiary?.trustee_OtherName)}
                                        errorMessage={ isInputValid(beneficiary?.trustee_OtherName) === 'error' ? 'Please enter last name' : undefined }
                                        onPersist={(e) => handleChange(e)}
                                    />
                                    <OmFormInputFieldWrapper
                                        label="Mobile number"
                                        id="trustee_MobileNumber"
                                        name="trustee_MobileNumber"
                                        maxLength={13}
                                        // prefixString="+233"
                                        labelAlwaysShow={true}
                                        isSensitive
                                        required
                                        formId="schemeDetailsForm"
                                        size="full"
                                        align="left"
                                        type="text"
                                        placeholder="e.g. +233 54 891 2451"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={beneficiary?.trustee_MobileNumber}
                                        state={isInputValid(beneficiary?.trustee_MobileNumber)}
                                        errorMessage={ isInputValid(beneficiary?.trustee_MobileNumber) === 'error' ? 'Please enter mobile number.' : undefined }
                                        // hintText= 'If Beneficiary is a Minor please provide the Contact Number of Person with Legal Primary Custody and caregiver of the minor'
                                        onPersist={(e) => handleChange(e)}
                                    />
                                    <OmFormInputFieldWrapper
                                        label="Email"
                                        id="trustee_email"
                                        name="trustee_email"
                                        labelAlwaysShow={true}
                                        isSensitive
                                        required
                                        formId="schemeDetailsForm"
                                        size="full"
                                        align="left"
                                        type="text"
                                        placeholder="eg. john@email.com"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={beneficiary?.trustee_email}
                                        state={isInputValid(beneficiary?.trustee_email)}
                                        errorMessage={ isInputValid(beneficiary?.trustee_email) === 'error' ? 'Please enter your email address.' : undefined }
                                        // hintText= 'If Beneficiary is a Minor please provide the Contact email of Person with Legal Primary Custody and caregiver of the minor'
                                        onPersist={(e) => handleChange(e)}
                                    />
                                </div>
                                : ''}

                        </div>

                        <div className="action-buttons">
                            <OmButton
                                className="cancel-button"
                                type="text"
                                size="small"
                                text="CANCEL"
                                onClick={() => cancelAndCloseEditing()}
                            />

                            <OmButton
                                disabled={!areAllInputsInvalid(getDataToCheck())}
                                className="save-button"
                                type="primary"
                                size="small"
                                text="SAVE BENEFICIARY"
                                onClick={() => saveBeneficiary()}
                            />
                        </div>
                    </div>
                </OmApplicationBeneficiaryDetails>
            </div>
        </OmSideDrawer>
    );
};

export default EditBeneficiariesDetailsSideDrawer;
