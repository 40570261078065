import React, { useContext, useState } from 'react';
import { DEFAULT_VALIDATION_DELAY } from '../../../../../ghana/configs/travelsure-configs/config';
import {OmFormInputFieldWrapper,OmModalLoader} from '@om/component-library-react';
import {isInputValid } from '../../../../../ghana/utils/travelsure-utils/util';
import OmButton from '../../../../../../../components/forms/OmButton';
import { hubtelPaymentVerifyOTP } from '../../server/pay';
import { TravelInsureContext } from '../../context/TravelInsureContext';


const HubtelVerify = ({ otpPrefix, clientReferenceId, toggleModal }) => {

    const { navigateContinue } = useContext(TravelInsureContext);

    const [loading, setLoading] = useState(false)
    const [otp, setOTP] = useState(undefined)


    const verifyOTP = async (e) => {
        if(otp){
            toggleLoading()
            const resp = await hubtelPaymentVerifyOTP(otp, otpPrefix, clientReferenceId)

            if(resp.successful){
                //verification ok
                toggleLoading()
                navigateContinue()
                toggleModal()
            }else{
                toggleLoading()
                alert(resp.message)
            }
        }else{
            alert('You need to enter an OTP code received on your phone to proceed!')
        }
    }

    const toggleLoading = () => {setLoading(!loading)}


    return (
        <>
            <div className="mvest-scheme-details-page">
                <div className="mvest-scheme-details-page-wrapper">
                    <h4 className="page-heading sessioncamhidetext">Please enter OTP Code received on your number</h4>
                    
                    <OmFormInputFieldWrapper
                        label="OTP Code Recieved On Phone"
                        id="otp"
                        name="otp"
                        labelAlwaysShow={true}
                        isSensitive
                        required
                        formId="schemeDetailsForm"
                        size="full"
                        align="left"
                        type="text"
                        placeholder="e.g. 1234"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={otp}
                        state={isInputValid(otp)}
                        errorMessage={isInputValid(otp) === 'error' ? 'Please enter your OTP' : undefined }
                        onPersist={(e) => setOTP(e.detail)}
                    />
           
                    <OmButton
                        iconName="search"
                        slot="right"
                        onClick={verifyOTP}
                        type="primary"
                        size="small"
                        text='Validate Policy Number' />
            

                    <OmModalLoader
                        slot="loading-modal"
                        loaderText="Please wait while we validate your OTP Code..."
                        verticalAlignMiddle={true}
                        open={loading}
                    />
                </div>
            </div>
        </>
    );
};

export default HubtelVerify;
