export const PAGE_INDICES = {
    QUOTATION: 0,
    PERSONAL_DETAILS: 1,
    BENEFICIARIES: 2,
    BENEFICIARIES_SPLIT: 3,
    REVIEW_APPLICATION: 4,
    SUCCESS: 5,
};

export const PROGRESS_BAR_STEPS = [
    'Quotation',
    'About you',
    'Beneficiary',
    'Confirmation',
].join('|');
