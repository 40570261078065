import React, { useContext } from 'react';
import './ReviewPage.scss';
import { TravelInsureContext } from '../../context/TravelInsureContext';
import { PAGE_INDICES } from '../../constants/routing';
import { OmApplicationReviewSection,OmLabeledValueSummary,OmCheckBox,OmFormError,OmApplicationSummaryDetailsCard,OmModalLoader } from '@om/component-library-react';
import { OmdsCell,OmdsGrid } from '@mom-omds/react-design-system-components/dist/generated/components';
import SelectTelco from '../modal/SelectTelco';

const ReviewPage = () => {
    const {
        navigateTo,
        quotationData,
        beneficiaryListDataTravel,
        hasAcceptedTermsAndCondition,
        acceptTermsAndCondition,
        togglePaymentModal,
        paymentModal
    } = useContext(TravelInsureContext);

    const joinName = (nameArray) => { return nameArray.join(' ') };

    const toggle = () => { acceptTermsAndCondition();  };

    return (
        <div id='travel_confirmation'>
            <OmdsGrid isFixed>
                <OmdsCell span="row" span-md="8">
                    <div>
                        <h4 className="page-heading sessioncamhidetext">Lastly, please confirm your details</h4>
                        <OmApplicationSummaryDetailsCard>
                            <div slot="content">
                                <p><strong>Total monthly premium is:</strong></p>
                                <h3 style={{ marginHeight: '6px' }}>GH₵ {quotationData?.schemeDetails?.travel_cost}</h3>
                            </div>
                        </OmApplicationSummaryDetailsCard>
                        <div>
                            <OmApplicationReviewSection titleHeader="Personal Details">
                                <span
                                    slot="edit-link"
                                    onClick={() => navigateTo(PAGE_INDICES.PERSONAL_DETAILS)}
                                >
                                    <h6 className="small">
                                        <strong>EDIT</strong>
                                    </h6>
                                </span>
                                <div slot="section-body">
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Full name"
                                                value={joinName([
                                                    quotationData?.schemeDetails?.title,
                                                    quotationData?.schemeDetails?.first_name,
                                                    quotationData?.schemeDetails?.last_name,
                                                ])}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Date of birth"
                                                value={quotationData?.schemeDetails?.birth_date}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Gender"
                                                value={quotationData?.schemeDetails?.gender}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Country of birth"
                                                value={quotationData?.schemeDetails?.nationality}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Mobile number"
                                                value={quotationData?.schemeDetails?.mobile_number}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Occupation"
                                                value={quotationData?.schemeDetails?.occupation}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Postal Address"
                                                value={quotationData?.schemeDetails?.postal_address}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Passport Number"
                                                value={quotationData?.schemeDetails?.passport_number}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="5">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Do you live in Ghana?"
                                                value={quotationData?.schemeDetails?.live_in_ghana}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Are you politically exposed person?"
                                                value={quotationData?.schemeDetails?.politically_exposed}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                </div>
                            </OmApplicationReviewSection>

                            <OmApplicationReviewSection titleHeader="Travel Details">
                                <span slot="edit-link" onClick={() => navigateTo(PAGE_INDICES.QUOTATION)}>
                                    <h6 className="small"><strong>EDIT</strong></h6>
                                </span>
                                <div slot="section-body">
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Depature date"
                                                value={quotationData?.schemeDetails?.date_of_depature}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Return Date"
                                                value={quotationData?.schemeDetails?.date_of_return}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Destination"
                                                value={quotationData?.schemeDetails?.destination}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Premium"
                                                value={quotationData?.schemeDetails?.travel_cost}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                </div>
                            </OmApplicationReviewSection>

                            {beneficiaryListDataTravel && beneficiaryListDataTravel.length > 0 && (
                                <OmApplicationReviewSection titleHeader="Beneficiary Details">
                                    <span
                                        slot="edit-link"
                                        onClick={() => navigateTo(PAGE_INDICES.BENEFICIARIES)}
                                    >
                                        <h6 className="small">
                                            <strong>EDIT</strong>
                                        </h6>
                                    </span>
                                    {beneficiaryListDataTravel.length > 1 ? (
                                        <div slot="section-body">
                                            {beneficiaryListDataTravel.map((beneficiary, index) => {
                                                return (
                                                    <div key={index}>
                                                        <OmdsGrid>
                                                            <OmdsCell span="row" span-md="6">
                                                                {' '}
                                                                <OmLabeledValueSummary
                                                                    isSensitive={true}
                                                                    className="beneficiary-name"
                                                                    label="Full name"
                                                                    value={joinName([
                                                                        beneficiary?.firstname,
                                                                        beneficiary?.OtherName,
                                                                    ])}
                                                                />
                                                            </OmdsCell>
                                                            <OmdsCell span="row" span-md="7..">
                                                                {' '}
                                                                <OmLabeledValueSummary
                                                                    className="beneficiary-split"
                                                                    label="Split"
                                                                    value={beneficiary?.perc_alloc + '%'}
                                                                />
                                                            </OmdsCell>
                                                        </OmdsGrid>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div slot="section-body">
                                            <OmdsGrid>
                                                <OmdsCell span="row" span-md="6">
                                                    <OmLabeledValueSummary
                                                        isSensitive={true}
                                                        className="beneficiary-name"
                                                        label="Full name"
                                                        value={joinName([
                                                            beneficiaryListDataTravel[0]?.firstname,
                                                            beneficiaryListDataTravel[0]?.OtherName,
                                                        ])}
                                                    />
                                                </OmdsCell>
                                            </OmdsGrid>
                                        </div>
                                    )}
                                </OmApplicationReviewSection>
                            )}
                        </div>
                        <div style={{ marginTop: '3rem' }} className="check-box-container">
                            <OmCheckBox
                                id="terms-and-conditions-checkbox"
                                checked={hasAcceptedTermsAndCondition}
                                onOnCheckBoxChanged={() => toggle()}
                            >
                                <p slot="text-slot">
                  I accept the&nbsp;
                                    <a target="_blank" rel="noopener noreferrer" style={{color: '#50b848'}}
                                        href="https://eu-assets.contentstack.com/v3/assets/blta881ace037f02dd2/blt7219a3b87fa65d04/661f7ffac6aae926b62ef5a7/TRAVEL_INSURANCE_TERMS_AND_CONDITIONS_Old_Mutual_Life_Assurance_Company_v2_(8).pdf">
                    declarations.
                                    </a>
                                </p>
                            </OmCheckBox>
                            <br />
                            <OmFormError message={hasAcceptedTermsAndCondition === true ? undefined : 'Please select checkbox before you can continue'} />
                        </div>
                    </div>
                </OmdsCell>
            </OmdsGrid>

            <OmModalLoader
                slot="loading-modal"
                loaderText="Please wait..."
                verticalAlignMiddle={true}
                open={quotationData?.schemeDetails?.loading}
            />

            <SelectTelco open={paymentModal} onCloseHandler={() => togglePaymentModal()} />
        </div>
    );
};

export default ReviewPage;
