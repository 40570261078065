import { OCCUPATIONS } from '../../applications/travel-insurance/constants/refeferenceData/occupations';
import { INPUT_STATE } from '../../configs/travelsure-configs/config';
import { AGENTS } from '../../applications/travel-insurance/constants/refeferenceData/agents';

export const isBeneficiaryEntryNew = (id, arr) => {
    return arr.some((obj) => obj.id !== id);
};

export const getBeneficiaryIndex = (id, arr) => {
    return arr.findIndex((obj) => obj.id === id);
};

export const isInputValid = (val) => {
    if (val === null || (val !== undefined && val.toString().trim() === '' && val.toString().trim().length < 2)) {
        return INPUT_STATE.ERROR;
    } else if (val === undefined || !val) {
        return INPUT_STATE.UNTOUCHED;
    }
    return INPUT_STATE.VALID;
};

export const areAllInputsInvalid = (obj) => {
    return (
        obj &&
    Object.values(obj).every((val) => isInputValid(val) === INPUT_STATE.VALID)
    );
};

export const sumPercentageAllocationValues = (array) => {
    return array.reduce((total, obj) => {
        const age = parseInt(obj?.perc_alloc);
        if (!isNaN(age)) {
            return total + age;
        }
        return total;
    }, 0);
};


export const evenlySplitPercentageAllocation = (array) => {
    const length = array.length;
    const percAlloc = Math.floor(100 / length); // Calculate the base percentage allocation
    const remainder = 100 % length; // Calculate the remainder
    // Distribute the base percentage allocation among the array objects
    const result = array.map((obj, index) => ({
        ...obj,
        perc_alloc: percAlloc + (index < remainder ? 1 : 0),
    }));
    return result;
};

export const isValidGhanaCardNumber = (input) => {
    const regex = /^GHA-\d{9}-\d$/;
    return regex.test(input);
};

export const isValidDriversLicenseNumber = (input) => {
    const regex = /^[A-Z]{3}-\d{8}-\d{5}$/;
    return regex.test(input);
};

export const isValidDriversLicenseRefNumber = (input) => {
    const regex = /^\d{8}[A-Z]\d$/;
    return regex.test(input);
};
export const isValidPassportID = (input) => {
    const regex = /^[A-Z]{1}[0-9]{7}$/;
    return regex.test(input);
};

export const isValidVoterID = (input) => {
    const regex = /^\d{10}$/;
    return regex.test(input);
};

export const isValidSsnit = (input) => {
    const regex = /^\[A-Z]\d{12}$/;
    return regex.test(input);
};


export const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

export const isValidNumbers = (phone) => {
    const regex = /^([+]\d{2})?\d{9}$/;
    return regex.test(phone);
};

export const isValidName = (name) => {
    const regex = /^[a-zA-Z\s-]+$/;
    return regex.test(name);
};

export const isUserAbove15YearsOfBirth = (dateValue) =>{
    const today = new Date();
    const userDate = new Date(dateValue);
    const ageInMillis = today - userDate
    const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
    return ageInYears >= 15;
}

export const isUserAbove0YearOfBirth = (dateValue) =>{
    const today = new Date();
    const userDate = new Date(dateValue);
    const ageInMillis = today - userDate
    const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
    return ageInYears >= 0;
}

export const isUserAbove70YearOfBirth = (dateValue) =>{
    const today = new Date();
    const userDate = new Date(dateValue);
    const ageInMillis = today - userDate
    const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
    return ageInYears >= 70;
}

export const isDateInPast = (dateValue) => {
  
    const givenDate = new Date(dateValue)
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if(givenDate < today){
        //yes userDate is in the past
        return true;
    }else {
        // is in the future
        return false;
    }
}

export const isDateInPastQuote = (dateValue, compareDate) => new Date(compareDate) < new Date(dateValue);


export const flattenObject = (obj) => {
    const flattened = {};
    function flatten(obj) {
        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                flatten(obj[key], key);
            } else {
                flattened[key] = obj[key];
            }
        }
    }
    flatten(obj);
    return flattened;
}

export const  objectToFormData = (obj) => {
    const formData = new FormData();

    function appendFormData(data, prefix) {
        if (Array.isArray(data)) {
            data.forEach((value, index) => {
                const arrayKey = `${prefix}[${index}]`;
                appendFormData(value, arrayKey);
            });
        } else if (typeof data === 'object' && data !== null) {
            Object.entries(data).forEach(([key, value]) => {
                const nestedKey = prefix ? `${prefix}.${key}` : key;
                appendFormData(value, nestedKey);
            });
        } else {
            formData.append(prefix, data);
        }
    }
    appendFormData(obj);
    return formData;
}

export const objectArrayToFormData = (arr) => {
    const formData = new FormData();

    arr.forEach((arr, index) => {
        for (let key in arr) {
            key != 'id' && formData.append(
                `benef_data[${index}][${key}]`,
                arr[key]
            );
        }
    });

    return formData;
}

export const formatFileSize = (bytes) => {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export const Redirect = (url) => {
    return new Promise((resolve, reject) => {
        if (typeof window !== 'undefined') {
            window.location.replace(url);
            resolve();
        } else {
            reject(new Error('Window object is not available..'));
        }
    });
};

export const travelInsurePremium = (depature_date, return_date) => {
    if((depature_date == null || return_date == null) || (depature_date == undefined || return_date == undefined)){
        return 0;
    }
    const date1 = new Date(depature_date);
    const date2 = new Date(return_date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <=7){
        return 120;
    }else if(diffDays >= 8 && diffDays <= 10){
        return 190;
    }else if(diffDays >= 11 && diffDays <= 14){
        return 230;
    }else if(diffDays >= 15 && diffDays <= 17){
        return 250;
    }else if(diffDays >= 18 && diffDays <= 21){
        return 300;
    }else if(diffDays >= 22 && diffDays <= 31){
        return 410;
    }else if(diffDays >= 32 && diffDays <= 38){
        return 520;
    }else if(diffDays >= 39 && diffDays <= 45){
        return 610;
    }else if(diffDays >= 46 && diffDays <= 60){
        return 670;
    }else if(diffDays >= 61 && diffDays <= 90){
        return 720;
    }else if(diffDays >= 91 && diffDays <= 365){
        return 1240;
    }else{
        return 0;
    }
}

export const travelInsurePremiumDays = (depature_date, return_date) => {
    const date1 = new Date(depature_date);
    const date2 = new Date(return_date);
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export const getBaseTravelInsureURL = () => {
    //getting the base url from the browser
    return process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-dms.oldmutual.com.gh:443' : 'https://prod-dms.oldmutual.com.gh';
}

export const relationshipAcronym = (relationship) => {
    let r = null;
    if(relationship == 'Son'){
        r = 'SN';
    }else if(relationship == 'Daughter'){
        r = 'DT';
    }else if(relationship == 'Aunt'){
        r = 'AN';
    }else if(relationship == 'Brother'){
        r = 'BR';
    }else if(relationship == 'Cousin'){
        r = 'CS';
    }else if(relationship == 'Father'){
        r = 'FA';
    }else if(relationship == 'Mother'){
        r = 'MO';
    }else if(relationship == 'Sister'){
        r = 'SS';
    }else if(relationship == 'Father in-law'){
        r = 'FI';
    }else if(relationship == 'Mother in-law'){
        r = 'MI';
    }else if(relationship == 'Spouse'){
        r = 'SP';
    }else if(relationship == 'Brother in-law'){
        r = 'BRI';
    }else if(relationship == 'Sister in-law'){
        r = 'SSI';
    }else if(relationship == 'Daughter in-law'){
        r = 'DTI';
    }else if(relationship == 'Grand Daughter'){
        r = 'GD';
    }else if(relationship == 'Grand Mother'){
        r = 'GR';
    }else if(relationship == 'Nephew'){
        r = 'NP';
    }else if(relationship == 'Niece'){
        r = 'NI';
    }else if(relationship == 'Uncle'){
        r = 'UN';
    }else if(relationship == 'Wife'){
        r = 'WF';
    }else if(relationship == 'Son in-law'){
        r = 'SNI';
    }else if(relationship == 'Neighbour'){
        r = 'NB';
    }
    return r;
}

export const getEmergentPayCredentials = () => {
    return {
        return_url: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://d1b2sh1ghml15h.cloudfront.net/travel-insurance' : 'https://www.oldmutual.com.gh/travel-insurance',
        payment_url: 'https://test-payment.oldmutual.com.gh/ProcessPayment', // used test because  the payment company do not  have a  live env as  oof now so test uses prod
        // client_id: process.env.GATSBY_DMS_CLIENT_ID,
        // client_secret: process.env.GATSBY_DMS_CLIENT_SECRET
        client_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '9275f1fa-11de-4482-a8fd-fd5e0c4579d2' : '9915ea80-36e6-4ec1-8e47-5834c392dd7d',
        client_secret: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'pIMsFbwmFjWwYZF4MGZqlBBRDBlqPycGiazl6El6' : 'W4A9DvqpOSmeTqXoY0OwINWf7my0JQ7xdHeldRrR'
    }
}

export const generateString = (length) => {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const stripSystemEmojis = (data) => {
    return data
        .replace(
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,'')
        .replace(/\s+/g, ' ')
        .trim();
}

export const getAgeValue = (dateValue) =>{
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const userDate = new Date(dateValue);
    const ageInMillis = today - userDate
    const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
    return Math.floor(ageInYears);
}

export const getTitleReferential = (data) => {
    switch (data) {
    case 'Miss':
        return '1';
        break;
        
    case 'Mr':
        return '2';
        break;

    case 'Mrs':
        return '3';
        break;

    case 'Dr':
        return '4';
        break;

    case 'Rev':
        return '6';
        break;

    case 'Prof':
        return '5';
        break;

    case 'Eng':
        return '7';
        break;

    case 'Pastor':
        return '8';
        break;

    case 'Hon':
        return '9';
        break;

    case 'Alhaji':
        return '10';
        break;

    case 'Ms':
        return '11';
        break;

    case 'Madam':
        return '12';
        break;
            
    default:
        return '0';
        break;
    }
}
export const getMaritalReferential = (data) => {
    if(data === 'Single'){
        return '4';
    }else if(data === 'Married'){
        return '2';
    }else if(data === 'Divorced'){
        return '1';
    }else if(data === 'Widower'){
        return '3';
    }else if(data === 'Widow'){
        return '5';
    }else{
        return '6';
    }
}
export const getOccupationReferential = (data) => {
    const occupations = OCCUPATIONS;
    if(occupations == null){
        return '104';
    }
    let filteredOccupation = occupations.filter((_occupation) => {
        return _occupation.occupation_name === data;
    });

    return filteredOccupation[0]?.occupation_code;
}
export const getNatioalityReferential = (data) => {
    const nationalities = JSON.parse(localStorage.getItem('nationalities'));
    if(nationalities == null){
        return 'GHA';
    }
    let filteredNationality = nationalities.filter((_nationality) => {
        return _nationality.Name === data.toUpperCase();
    });

    return filteredNationality[0]?.Code;
}

export const stripZeroPhoneNumber = (phonenumber) => {
    if (phonenumber.startsWith('0'))
    {
        return phonenumber = phonenumber.substr(1);
    }
    return phonenumber;
}


export const getHubtelCredentials = () => {
    return {
        return_url: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://d1b2sh1ghml15h.cloudfront.net/travel-insurance' : 'https://www.oldmutual.com.gh/travel-insurance',
        payment_url: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-payintegrations.oldmutual.com.gh/api/v2/hubteldirectdebitpayment/initializev2':'https://payintegrations.oldmutual.com.gh/api/v2/hubteldirectdebitpayment/initializev2' ,
        payment_verifyOTP: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-payintegrations.oldmutual.com.gh/api/v1/hubteldirectdebitpayment/verify-otp':'https://payintegrations.oldmutual.com.gh/api/v1/hubteldirectdebitpayment/verify-otp' ,
        baseURL: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-payintegrations.oldmutual.com.gh/':'https://payintegrations.oldmutual.com.gh/' ,
        API_KEY: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'ApiKey ty2h2k64i4z7glfg00wyzzuqw7tzzk7j' : 'ApiKey E6adPETAkDZl7hT0mN1MhTx8jwqCECL0__'
    }
}

export const hubtelNetworkType = (telco='MTN') => {
    if(telco == 'MTN'){
        return 0;
    }else if(telco == 'Vodafone'){
        return 1;
    }else if(telco == 'AirtelTigo'){
        return 2;
    }
}

export const getAgentsReferential = (data) => {
    const agents = AGENTS;

    let filteredAgent = agents.filter((_agent) => {
        return `${_agent.AgentNoCode} (${_agent.name})` === data;
    });

    return filteredAgent[0]?.id;
}

export const removeLocalStorage = () => {
    localStorage.removeItem('quotationData');
    localStorage.removeItem('t_activePageIndex');
    localStorage.removeItem('OMIT_policy_no');
    localStorage.removeItem('beneficiaryListDataTravel');
}

export const OM_API_BASE_URL = process.env.GATSBY_ENVIRONMENT_NAME === 'prod' ? 'https://goals-api.my.oldmutual.co.za/om-api/mvest' : 'https://goals-qa.digital.omapps.net:8080/wa/om-api/mvest';

export const pushToGAonNext = (_eventName=null, _payload=null) => {
    const index = localStorage.getItem('t_activePageIndex');

    let payload = {quotationData: index == 2 || index == 3 ? JSON.parse(localStorage.getItem('beneficiaryListDataTravel')) : JSON.parse(localStorage.getItem('quotationData')), t_activePageIndex: index}

    if(index == 0){
        window.dataLayer.push({'event' : 'get_quote', 'response' : payload })
    }else if(index == 1){
        window.dataLayer.push({'event' : 'add_personal_info', 'response' : payload })
    }else if(index == 2 || index == 3){
        window.dataLayer.push({'event' : 'add_beneficiary', 'response' : payload })
    }else{
        window.dataLayer.push({'event' : _eventName, 'response' : _payload })
    }
}
