import { Redirect, getEmergentPayCredentials, getHubtelCredentials, hubtelNetworkType, stripZeroPhoneNumber } from '../../../../ghana/utils/travelsure-utils/util';
import { generateString } from '../../../utils/travelsure-utils/util';

let quotationData;
if (typeof window != 'undefined' && typeof localStorage != 'undefined') {
    quotationData = JSON.parse(localStorage.getItem('quotationData'));
} else {
    quotationData = null; // Handle the case when localStorage is not available
}

export const initiatePayment = async () => {
    const _data = JSON.parse(localStorage.getItem('quotationData'));
    const payment_url = 'https://test-payment.oldmutual.com.gh/ProcessPayment';
    const postData = {
        name: `${_data?.schemeDetails?.first_name} ${_data?.schemeDetails?.last_name}`,
        email: _data?.schemeDetails?.email_address,
        mobile: '+233' + stripZeroPhoneNumber(_data?.schemeDetails?.momo_number.toString()), 
        return_url: getEmergentPayCredentials().return_url,
        currency: 'GHS',
        amount: _data.schemeDetails.travel_cost.toString(),
        order_id: `OMIT/${_data.schemeDetails.passport_number}/${generateString(4)}`, // unique
        order_desc: `${localStorage.getItem('OMIT_policy_no')}|travel_insurance`,    // member_no
        // order_desc: `OM Travel Insurance Subscription for ${_data?.schemeDetails?.first_name} ${_data?.schemeDetails?.last_name}`,    // custom description
    };
    try {
        const response = await fetch(payment_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': 'hgjku78uo8-cte736565r7656x4538uip80v-0-ty47536',
            },
            body: JSON.stringify(postData),
        });
        const result = await response.json();
        if (result?.status_code === 1) {
            // Store the result.trans_ref_no for Reconciliation
            // console.log(result?.redirect_url);
            Redirect(result?.redirect_url);
        } else {
            console.log(result);
        }
    } catch (error) {
        console.error('An error occurred while making the POST request for payment::', error);
        throw error;
    }
};

export const initiateHubtelPayment = async () => {
    const _data = JSON.parse(localStorage.getItem('quotationData'));
    
    const payload = {
        channel: hubtelNetworkType(localStorage.getItem('OMIT_Telco')),
        name: `${_data?.schemeDetails?.first_name} ${_data?.schemeDetails?.last_name}`,
        description: localStorage.getItem('OMIT_policy_no'),
        phone: '233' + stripZeroPhoneNumber(_data?.schemeDetails?.momo_number), 
        amount: _data.schemeDetails.travel_cost.toString(),
        proposalNumber: localStorage.getItem('OMIT_policy_no'),  
    };
    try {
        const response = await fetch(getHubtelCredentials().payment_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getHubtelCredentials().API_KEY,
            },
            body: JSON.stringify(payload),
        });
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('An error occurred while making the POST request for payment::', error);
        throw error;
    }
}

export const hubtelPaymentVerifyOTP = async (otp, otpPrefix, clientReferenceId) => {
    const payload = {
        otpCode: `${otpPrefix} ${otp}`,
        clientReferenceId: clientReferenceId
    }
    try {
        const response = await fetch(getHubtelCredentials().payment_verifyOTP, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getHubtelCredentials().API_KEY,
            },
            body: JSON.stringify(payload),
        });
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('An error occurred while making the POST request for payment::', error);
        throw error;
    }
}

export const hubtelPaymentVerifyTransaction = async (clientReferenceId) => {
    
    try {
        const response = await fetch(`${getHubtelCredentials().baseURL}api/v1/hubteldirectdebitpayment/directdebitcharge-status-check/${clientReferenceId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getHubtelCredentials().API_KEY,
            },
        });
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('An error occurred while making the POST request for debit charge check::', error);
        throw error;
    }
}