import React, { useContext, useEffect, useState } from 'react';

import OmButton from '../../../../../components/forms/OmButton';
import { OmApplicationWizardFooter } from '@om/component-library-react';
import { TravelInsureContext } from './context/TravelInsureContext';
import { PAGE_INDICES } from './constants/routing';
import { areAllInputsInvalid, getAgentsReferential, getMaritalReferential, getNatioalityReferential, getOccupationReferential, getTitleReferential, sumPercentageAllocationValues, travelInsurePremiumDays, pushToGAonNext, generateString} from '../../../ghana/utils/travelsure-utils/util';
import { sendClientRegistration, sendClientBeneficiaries, checkIfPolicyIsPaid, updatePaymentStatusForQA, hubspotGetContact, hubspotCreateContact, hubspotCreateDeal, hubspotAttachContactToDeal, hubspotUpdateContact } from './server/api';
import useUrlParams from './hooks/useUrlParams';


const ApplicationWizardFooter = () => {

    const urlParams = useUrlParams();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const {
        t_activePageIndex,
        hasAcceptedTermsAndCondition,
        navigateContinue,
        navigatePrevious,
        quotationData,
        beneficiaryListDataTravel,
        updateMVestData,
        togglePaymentModal
    } = useContext(TravelInsureContext);

    let continueText = t_activePageIndex === PAGE_INDICES.REVIEW_APPLICATION ? (urlParams?.status_code == 1 ? (isSubmitting ? 'PROCESSING...' : 'MAKE PAYMENT') : 'SUBMIT & PAY') : ( beneficiaryListDataTravel.length >= 1 && t_activePageIndex === PAGE_INDICES.BENEFICIARIES ? 'CONTINUE' : 'CONTINUE');
    // const continueText = t_activePageIndex === PAGE_INDICES.REVIEW_APPLICATION ? "SUBMIT" : ( beneficiaryListDataTravel.length >= 1 && t_activePageIndex === PAGE_INDICES.BENEFICIARIES ? "SPLIT PERCENTAGE" : "CONTINUE");
    const isLastPage = t_activePageIndex === PAGE_INDICES.SUCCESS;

    useEffect(() => {
        if(t_activePageIndex === PAGE_INDICES.REVIEW_APPLICATION){
            checkPolicyStatus();
        }    
    },[t_activePageIndex])

    const checkPolicyStatus = async () => {
        if(localStorage.getItem('OMIT_policy_no')){
            setIsSubmitting(true)
            if(process.env.GATSBY_ENVIRONMENT_NAME == 'qa'){
                await updatePaymentStatusForQA(localStorage.getItem('OMIT_policy_no'))
                navigateContinue()
            }else{
                setIsSubmitting(true)
                const resp = await checkIfPolicyIsPaid(localStorage.getItem('OMIT_policy_no'));

                if(resp?.success == true){
                    navigateContinue()
                }else{
                    const queryString = window.location.search;
                    const UP = new URLSearchParams(queryString);
                
                    if(UP.get('status_code') == 1){
                        const updateResp = await updatePaymentStatusForQA(localStorage.getItem('OMIT_policy_no'))
                        if(updateResp?.success == true){
                            navigateContinue()
                        }else{
                            setIsSubmitting(false)
                        }
                    }
  
                    setIsSubmitting(false)
                }
            }
        }
    }

    const validateFormNextStep = () => {
        if (t_activePageIndex === PAGE_INDICES.QUOTATION) {
            return quotationData?.schemeDetails?.travel_cost > 0;
        }else if (t_activePageIndex === PAGE_INDICES.PERSONAL_DETAILS) {
            let _payload = {
                passport_number: quotationData?.schemeDetails?.passport_number,
                first_name: quotationData?.schemeDetails?.first_name,
                last_name: quotationData?.schemeDetails?.last_name,
                birth_date: quotationData?.schemeDetails?.birth_date,
                date_of_depature: quotationData?.schemeDetails?.date_of_depature,
                date_of_return: quotationData?.schemeDetails?.date_of_return,
                nationality: quotationData?.schemeDetails?.nationality,
                destination: quotationData?.schemeDetails?.destination,
                gender: quotationData?.schemeDetails?.gender,
                marital_status: quotationData?.schemeDetails?.marital_status,
                occupation: quotationData?.schemeDetails?.occupation,
                mobile_number: quotationData?.schemeDetails?.mobile_number,
                momo_number: quotationData?.schemeDetails?.momo_number,
                postal_address: quotationData?.schemeDetails?.postal_address,
                live_in_ghana: quotationData?.schemeDetails?.live_in_ghana,
                politically_exposed: quotationData?.schemeDetails?.politically_exposed,
                title: quotationData?.schemeDetails?.title,
                email_address: quotationData?.schemeDetails?.email_address,
            }
            return areAllInputsInvalid(_payload);
        }else if (t_activePageIndex === PAGE_INDICES.BENEFICIARIES) {
            for (let idx = 0; idx < beneficiaryListDataTravel.length; idx++) {
                const beneficiary = beneficiaryListDataTravel[idx];
                return areAllInputsInvalid(beneficiary)
            }
        } else if (t_activePageIndex === PAGE_INDICES.BENEFICIARIES_SPLIT) {
            for (let idx = 0; idx < beneficiaryListDataTravel.length; idx++) {
                const beneficiary = beneficiaryListDataTravel[idx];

                return areAllInputsInvalid(beneficiary) && sumPercentageAllocationValues(beneficiaryListDataTravel) == 100
            }
        } else if (t_activePageIndex === PAGE_INDICES.REVIEW_APPLICATION) {
            return hasAcceptedTermsAndCondition;
        }
        return;
    };

    const pay = () => togglePaymentModal()
       
    const submit = async () => {
        try {
            setIsSubmitting(true)
            if(urlParams?.status_code == 3 || urlParams?.status_code == 2){
                pay()
                return;
            }

            //=== google analytics ===
            pushToGAonNext('begin_checkout', {currency: 'GHS', value: quotationData?.schemeDetails?.travel_cost, items: [{ item_id: generateString(4), item_name: 'Travel Insurance' }], t_activePageIndex: t_activePageIndex})
            
            updateMVestData({ loading: true }, 'schemeDetails');
            const registerPayload = {
                surname: quotationData?.schemeDetails.last_name,
                other_name: quotationData?.schemeDetails.first_name,
                passportNo: quotationData?.schemeDetails.passport_number,
                mobile: quotationData?.schemeDetails.mobile_number,
                sex: quotationData?.schemeDetails.gender == 'Male' ? 'M' : 'F',
                travel_days: travelInsurePremiumDays(quotationData?.schemeDetails?.date_of_depature, quotationData?.schemeDetails?.date_of_return),
                date_from: quotationData?.schemeDetails?.date_of_depature,
                destination_country: quotationData?.schemeDetails?.destination,
                title: getTitleReferential(quotationData?.schemeDetails?.title),
                birthdate: quotationData?.schemeDetails?.birth_date,
                id_type: 'PP',
                IdNumber: quotationData?.schemeDetails?.passport_number,
                marital_status: getMaritalReferential(quotationData?.schemeDetails?.marital_status),
                occupation: getOccupationReferential(quotationData?.schemeDetails?.occupation),
                email: quotationData?.schemeDetails?.email_address,
                Nationality: getNatioalityReferential(quotationData?.schemeDetails?.nationality) == null ? 'GHA' : getNatioalityReferential(quotationData?.schemeDetails?.nationality)
            }
            if(quotationData?.schemeDetails?.agentCode){
                registerPayload.agent_no = getAgentsReferential(quotationData?.schemeDetails?.agentCode)
            }
            // console.log('Registering payload::',registerPayload)
            
            const res = await sendClientRegistration(registerPayload)
            // console.log('Registering response::',res)

            if (res?.success == true ){
       
                localStorage.setItem('OMIT_policy_no', res?.policy_no)
                const benficiary_response = await sendClientBeneficiaries(res?._token, beneficiaryListDataTravel, res?.policy_no)
                // console.log('Beneficiary response::',benficiary_response)
                if(benficiary_response?.success == true){
                    // setIsSubmitting(false)

                    // ==== create deal on CRM =====
                    if(process.env.GATSBY_ENVIRONMENT_NAME === 'prod'){
                        await crmDeal(quotationData?.schemeDetails?.travel_cost, res?.policy_no)
                    }
                    // ==== End of creating deal on CRM =====

                    pushToGAonNext('purchase', {currency: 'GHS', transaction_id: res?.policy_no, value: quotationData?.schemeDetails?.travel_cost, items: [{ item_id: generateString(4), item_name: 'Travel Insurance', transaction_id: res?.policy_no }], t_activePageIndex: t_activePageIndex})
                    updateMVestData({ loading: false }, 'schemeDetails');
                    pay()
                }else{
                    updateMVestData({ loading: false }, 'schemeDetails');
                    alert('Beneficiary error.',benficiary_response.message)
                }
        
            }else{
                updateMVestData({ loading: false }, 'schemeDetails');
                setIsSubmitting(false)
            }
        } catch (error) {
            updateMVestData({ loading: false }, 'schemeDetails');
            console.error('Submission failed:', error);
        }

    }

    const crmContact = async () => {
        if(localStorage.getItem('CRM_CONTACT_ID') == null || localStorage.getItem('CRM_CONTACT_ID') === undefined){
            const _crm = await hubspotGetContact(quotationData?.schemeDetails?.email_address)

            if(!_crm.id){
                let payload = {
                    email: quotationData?.schemeDetails?.email_address,
                    firstname: quotationData?.schemeDetails?.first_name,
                    lastname: quotationData?.schemeDetails?.last_name,
                    phone: quotationData?.schemeDetails?.mobile_number,
                    hubspot_owner_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '1421520828' : '1327009943'
 
                }
                const createCRMContact = await hubspotCreateContact(payload);
                localStorage.setItem('CRM_CONTACT_ID', createCRMContact?.id)
            }else{
                localStorage.setItem('CRM_CONTACT_ID', _crm?.id)
            }
        }else{
            let payload = {
                email: quotationData?.schemeDetails?.email_address,
                firstname: quotationData?.schemeDetails?.first_name,
                lastname: quotationData?.schemeDetails?.last_name,
                phone: quotationData?.schemeDetails?.mobile_number,
                hubspot_owner_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '1421520828' : '1327009943'
 
            }
            await hubspotUpdateContact(payload, localStorage.getItem('CRM_CONTACT_ID'));
        }
    }

    const crmDeal = async (amount, policyNumber) => {

        const closeDate = new Date();
        closeDate.setMonth(closeDate.getMonth() + 1);

        let crmPayload = {
            amount: amount,
            closedate: closeDate.toISOString(),
            dealname: `${quotationData?.schemeDetails?.first_name} ${quotationData?.schemeDetails?.last_name} - Travel Insurance ${policyNumber}`,
            pipeline: 'default',
            dealstage: '376751587',
            hubspot_owner_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '1421520828' : '1327009943',
            dealtype: 'newbusiness',
            plan_name: 'Travel Insurance',
            source_of_deduction: 'MoMo',
            proposal_policy_number: policyNumber,
            mobilephone: quotationData?.schemeDetails?.momo_number
        }

        const resp = await hubspotCreateDeal(crmPayload)
        // console.log('Deal Resp::', resp);

        // assign contact to deal
        if(resp?.id){
            await hubspotAttachContactToDeal(resp?.id, localStorage.getItem('CRM_CONTACT_ID'))
        } 
    }

    const nextActionButton = () => {
        if(t_activePageIndex === PAGE_INDICES.REVIEW_APPLICATION){
            submit()
        }else{
            pushToGAonNext()
            navigateContinue()
            if(t_activePageIndex === PAGE_INDICES.BENEFICIARIES && process.env.GATSBY_ENVIRONMENT_NAME === 'prod'){
                crmContact()
            }
        }
    }

    return (
        !isLastPage && (
            <OmApplicationWizardFooter
                index={t_activePageIndex}
                totalCount="totalSteps"
            >
                <OmButton
                    slot="left"
                    onClick={() => navigatePrevious()}
                    type="text"
                    size="small"
                    text="Previous"
                    disabled={t_activePageIndex <= PAGE_INDICES.QUOTATION}
                />

                <OmButton
                    disabled={!validateFormNextStep() || isSubmitting}
                    iconName="arrow_forward"
                    slot="right"
                    onClick={() => nextActionButton()}
                    type="primary"
                    size="small"
                    text={continueText}
                />
            </OmApplicationWizardFooter>
        )
    );
};

export default ApplicationWizardFooter;
