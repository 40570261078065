import React from 'react';
import { OmApplicationPage } from '@om/component-library-react';
import ApplicationLayout from '../../../../layouts/ApplicationLayout';
import ApplicationWizardHeader from '../applications/travel-insurance/ApplicationWizardHeader';
import ApplicationWizardFooter from '../applications/travel-insurance/ApplicationWizardFooter';
import { TravelInsureProvider } from '../applications/travel-insurance/context/TravelInsureContext';
import Outlet from '../applications/travel-insurance/pages/Outlet';

const TravelInsurancePage = () => {
    return (
        <TravelInsureProvider>
            <ApplicationLayout>
                <OmApplicationPage>
                    <div slot="header">
                        <ApplicationWizardHeader callMeBack={{}} />
                    </div>
                    <div slot="content-no-sidebar">
                        <Outlet />
                    </div>
                    <div slot="footer">
                        <ApplicationWizardFooter />
                    </div>
                </OmApplicationPage>
            </ApplicationLayout>
        </TravelInsureProvider>
    );
};

export default TravelInsurancePage;
