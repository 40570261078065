import React, { useContext } from 'react';
import './BeneficiariesSplitPage.scss';
import ApplicationBeneficiarySplitList from './ApplicationBeneficiarySplitList';
import { TravelInsureContext } from '../../context/TravelInsureContext';

const BeneficiariesSplitPage = () => {
    const { beneficiaryListDataTravel } = useContext(TravelInsureContext);
    return (
        <div className="mvest-beneficiaries-split">
            <div className="mvest-beneficiaries-split-wrapper">
                <h5 className="no-top-margin">
          Split your benefits <strong>between your beneficiaries</strong>
                </h5>

                <ApplicationBeneficiarySplitList
                    beneficiaryList={beneficiaryListDataTravel}
                />
            </div>
        </div>
    );
};

export default BeneficiariesSplitPage;
