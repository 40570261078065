import React, { useContext,useEffect,useState } from 'react';
import { DEFAULT_VALIDATION_DELAY } from '../../../../../../ghana/configs/travelsure-configs/config';
import {OmFormDropdownFieldWrapper, OmFormInputFieldWrapper, OmModalLoader} from '@om/component-library-react';
import { TravelInsureContext } from '../../../context/TravelInsureContext';
import { getAgeValue, isInputValid, isValidEmail, isValidName, isValidPassportID, stripSystemEmojis, stripZeroPhoneNumber } from '../../../../../../ghana/utils/travelsure-utils/util';
import { OmdsDatePickerField } from '@mom-omds/react-design-system-components/dist/generated/components';
import { PAGE_INDICES } from '../../../constants/routing';
import { getAllNationalityFromSlams, getAllOccupationFromSlams } from '../../../server/api';
const referenceNationalities = require('../../../constants/refeferenceData/countries');
const referenceGender = require('../../../constants/refeferenceData/genders');
const referenceMarital = require('../../../constants/refeferenceData/marital_status');
const referenceYesNo = require('../../../constants/refeferenceData/yes_no');
const referenceSalutation = require('../../../constants/refeferenceData/salutations')
const referenceOccupation = require('../../../constants/refeferenceData/occupations')
const agents = require('../../../constants/refeferenceData/agents');

const SchemeDetailsSection = () => {

    const { quotationData, updateMVestData, t_activePageIndex } = useContext(TravelInsureContext);

    const [occupations, setOccupations] = useState([]);

    useEffect(() => {
        if(t_activePageIndex === PAGE_INDICES.PERSONAL_DETAILS){
            getOccupationDataFromSlams()
        }
    },[])

    const getOccupationDataFromSlams = async () => {
        if(JSON.parse(localStorage.getItem('occupations')) != null){
            setOccupations(JSON.parse(localStorage.getItem('occupations')))
            return;
        }
        updateMVestData({ loading: true }, 'schemeDetails');
        let _occupations = await getAllOccupationFromSlams();
        _occupations = _occupations.filter((_occupation) => _occupation.occupation_name !== 'NOT DEFINED')
        setOccupations(_occupations);
        localStorage.setItem('occupations', JSON.stringify(_occupations))

        let _nationalities = await getAllNationalityFromSlams();
        _nationalities = _nationalities.filter((__nationality) => __nationality.Code !== '')
        localStorage.setItem('nationalities', JSON.stringify(_nationalities))

        updateMVestData({ loading: false }, 'schemeDetails');
    }


    const handleChange = (e) => {
        const value = e.detail;
        const input = e.target.name;

        let formattedValue = '';

        if (input === 'mobile_number') {
            formattedValue = value.startsWith('233') ? value : '233' + stripZeroPhoneNumber(value);
            if(value.length < 8){
                updateMVestData({ [input]: null }, 'schemeDetails');
                return;
            }
        }else if(input === 'first_name' || input === 'last_name'){
            if(!isValidName(value) || value.trim().length < 2){
                updateMVestData({ [input]: null }, 'schemeDetails');
                return;
            }
            formattedValue = stripSystemEmojis(value)
        }else if(input === 'birth_date'){
            console.log(value)
            console.log(getAgeValue(value));
            if(getAgeValue(value) < 70 && getAgeValue(value) >= 0){
                updateMVestData({ [input]: value }, 'schemeDetails');
                return;
            }
        }else{
            formattedValue = value;
        }
        updateMVestData({ [input]: formattedValue }, 'schemeDetails');
    }

    return (
        <div className="travel-quote-main" id='travel_about_you'>
            <h6 className="medium no-bottom-margin">Personal details</h6>

            <OmFormDropdownFieldWrapper
                id="title"
                name="title"
                useNativeMobileDropdown
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Title"
                align="left"
                type="text"
                value={quotationData?.schemeDetails?.title}
                state={isInputValid(quotationData?.schemeDetails?.title)}
                errorMessage={isInputValid(quotationData?.schemeDetails?.title) === 'error' ? 'Select a title that describes you' : undefined }
                options={
                    referenceSalutation &&
            JSON.stringify(
                referenceSalutation.SALUTATION.map((option) => ({
                    value: option.fullcode,
                    label: option.description,
                }))
            )
                }
                onOnSelect={(e) => updateMVestData({ title: e.detail.value }, 'schemeDetails') }
            />

            <OmFormInputFieldWrapper
                label="First name"
                id="first_name"
                name="first_name"
                labelAlwaysShow={true}
                isSensitive
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. John"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={quotationData?.schemeDetails?.first_name}
                state={isInputValid(quotationData?.schemeDetails?.first_name)}
                errorMessage={isInputValid(quotationData?.schemeDetails?.first_name) === 'error' ? 'Please enter your first name' : undefined }
                onPersist={(e) => handleChange(e)}
            />
            <OmFormInputFieldWrapper
                label="Surname"
                id="last_name"
                name="last_name"
                labelAlwaysShow={true}
                isSensitive
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. Doe"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={quotationData?.schemeDetails?.last_name}
                state={isInputValid(quotationData?.schemeDetails?.last_name)}
                errorMessage={ isInputValid(quotationData?.schemeDetails?.last_name) === 'error' ? 'Please enter your last name' : undefined }
                onPersist={(e) => handleChange(e)}
            />

            <OmFormDropdownFieldWrapper
                id="nationality"
                name="nationality"
                useNativeMobileDropdown
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Nationality"
                align="left"
                type="text"
                value={quotationData?.schemeDetails?.nationality}
                state={isInputValid(quotationData?.schemeDetails?.nationality)}
                errorMessage={isInputValid(quotationData?.schemeDetails?.nationality) === 'error' ? 'Please select your ationality' : undefined }
                options={
                    referenceNationalities &&
            JSON.stringify(
                referenceNationalities.COUNTRIES.map((option) => ({
                    value: option.fullcode,
                    label: option.description,
                }))
            )
                }
                onOnSelect={(e) => updateMVestData({ nationality: e.detail.value }, 'schemeDetails') }
            />

            <OmdsDatePickerField
                id='birth_date'
                label="Date of birth"
                name="birth_date"
                value={quotationData?.schemeDetails?.birth_date}
                hasError={isInputValid(quotationData?.schemeDetails?.birth_date) === 'error'}
                onPersist={(e) => handleChange(e)}
                placeholder="e.g. 1980-05-05">
                {isInputValid(quotationData?.schemeDetails?.birth_date) === 'error' ? (<span slot="hint-text">{'You must be at least 0 Year Of Age and not more than 70'}</span>) : undefined}
            </OmdsDatePickerField>

            <OmFormDropdownFieldWrapper
                id="gender"
                name="gender"
                useNativeMobileDropdown
                className="inline-field"
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Gender"
                align="left"
                type="text"
                value={quotationData?.schemeDetails?.gender}
                state={isInputValid(quotationData?.schemeDetails?.gender)}
                errorMessage={isInputValid(quotationData?.schemeDetails?.gender) === 'error' ? 'Please select your gender' : undefined }
                options={
                    referenceGender &&
                JSON.stringify(
                    referenceGender.GENDERS.map((option) => ({
                        value: option.fullcode,
                        label: option.description,
                    }))
                )
                }
                onOnSelect={(e) => updateMVestData({ gender: e.detail.value }, 'schemeDetails') }
            />

            {/* <OmFormInputFieldWrapper
                label="Occupation"
                id="occupation"
                name="occupation"
                labelAlwaysShow={true}
                isSensitive
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. Doctor"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={quotationData?.schemeDetails?.occupation}
                state={isInputValid(quotationData?.schemeDetails?.occupation)}
                errorMessage={isInputValid(quotationData?.schemeDetails?.occupation) === 'error' ? 'Please enter your occupation' : undefined }
                onPersist={(e) => handleChange(e)} /> */}

            <OmFormDropdownFieldWrapper
                id="occupation"
                name="occupation"
                useNativeMobileDropdown
                className="inline-field"
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Select occupation"
                align="left"
                type="text"
                value={quotationData?.schemeDetails?.occupation}
                state={isInputValid(quotationData?.schemeDetails?.occupation)}
                errorMessage={isInputValid(quotationData?.schemeDetails?.occupation) === 'error' ? 'Please select your occupation' : undefined }
                options={
                    referenceOccupation &&
                JSON.stringify(
                    referenceOccupation.OCCUPATIONS.map((option) => ({
                        value: option.occupation_name,
                        label: option.occupation_name,
                    }))
                )
                }
                onOnSelect={(e) => updateMVestData({ occupation: e.detail.value }, 'schemeDetails') }
            />

            <OmFormInputFieldWrapper
                label="Passport Number"
                id="passport_number"
                name="passport_number"
                labelAlwaysShow={true}
                isSensitive
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="Passport Number"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={quotationData?.schemeDetails?.passport_number}
                state={isInputValid(quotationData?.schemeDetails?.passport_number)}
                errorMessage={isInputValid(quotationData?.schemeDetails?.passport_number) === 'error' ? 'Enter your passport number' : 
                    !isValidPassportID(quotationData?.schemeDetails?.passport_number) ? 'Enter a valid passport number' : undefined}
                onPersist={(e) => handleChange(e)}
            />

            <OmFormDropdownFieldWrapper
                id="marital_status"
                name="marital_status"
                useNativeMobileDropdown
                className="inline-field"
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Marital Status"
                align="left"
                type="text"
                value={quotationData?.schemeDetails?.marital_status}
                state={isInputValid(quotationData?.schemeDetails?.marital_status)}
                errorMessage={isInputValid(quotationData?.schemeDetails?.marital_status) === 'error' ? 'Please select your marital status' : undefined }
                options={
                    referenceMarital &&
                JSON.stringify(
                    referenceMarital.MARITAL_STATUS.map((option) => ({
                        value: option.fullcode,
                        label: option.description,
                    }))
                )
                }
                onOnSelect={(e) => updateMVestData({ marital_status: e.detail.value }, 'schemeDetails') }
            />

            <OmFormDropdownFieldWrapper
                id="live_in_ghana"
                name="live_in_ghana"
                useNativeMobileDropdown
                className="inline-field"
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Do you live in Ghana?"
                align="left"
                type="text"
                value={quotationData?.schemeDetails?.live_in_ghana}
                state={isInputValid(quotationData?.schemeDetails?.live_in_ghana)}
                errorMessage={isInputValid(quotationData?.schemeDetails?.live_in_ghana) === 'error' ? 'Please select your answer' : undefined }
                options={
                    referenceYesNo &&
                JSON.stringify(
                    referenceYesNo.YES_NO.map((option) => ({
                        value: option.fullcode,
                        label: option.description,
                    }))
                )
                }
                onOnSelect={(e) => updateMVestData({ live_in_ghana: e.detail.value }, 'schemeDetails') }
            />
            <OmFormDropdownFieldWrapper
                id="politically_exposed"
                name="politically_exposed"
                useNativeMobileDropdown
                className="inline-field bottom-margin"
                required
                style={{marginBottom:'20px !important'}}
                size="full"
                formId="schemeDetailsForm"
                placeholder="Are you a politically exposed person?"
                align="left"
                type="text"
                value={quotationData?.schemeDetails?.politically_exposed}
                state={isInputValid(quotationData?.schemeDetails?.politically_exposed)}
                errorMessage={isInputValid(quotationData?.schemeDetails?.politically_exposed) === 'error' ? 'Please select your answer' : undefined }
                options={
                    referenceYesNo &&
                JSON.stringify(
                    referenceYesNo.YES_NO.map((option) => ({
                        value: option.fullcode,
                        label: option.description,
                    }))
                )
                }
                onOnSelect={(e) => updateMVestData({ politically_exposed: e.detail.value }, 'schemeDetails') }
            />

            <h6 className="medium no-bottom-margin" id="heading-anchor">Contact details</h6>
            <OmFormInputFieldWrapper
                label="Mobile number"
                id="mobile_number"
                name="mobile_number"
                maxLength={13}
                // prefixString="+233"
                labelAlwaysShow={true}
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. 054 891 2451"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={quotationData?.schemeDetails?.mobile_number}
                state={isInputValid(quotationData?.schemeDetails?.mobile_number)}
                errorMessage={ isInputValid(quotationData?.schemeDetails?.mobile_number) === 'error' ? 'Please enter your mobile number.' : undefined }
                onPersist={(e) => handleChange(e)}
            />

            <OmFormInputFieldWrapper
                label="Mobile Money Number (MTN or Telecel)"
                id="momo_number"
                name="momo_number"
                maxLength={10}
                labelAlwaysShow={true}
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. 054 891 2451"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={quotationData?.schemeDetails?.momo_number}
                state={isInputValid(quotationData?.schemeDetails?.momo_number)}
                errorMessage={ isInputValid(quotationData?.schemeDetails?.momo_number) === 'error' ? 'Please enter your mobile number.' : undefined }
                onPersist={(e) => handleChange(e)}
            />

            <OmFormInputFieldWrapper
                label="Email Address"
                id="email_address"
                name="email_address"
                labelAlwaysShow={true}
                type="email"
                isSensitive
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                keyboardMode="email"
                placeholder="e.g. johndoe@gmail.com"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={quotationData?.schemeDetails?.email_address}
                state={isInputValid(quotationData?.schemeDetails?.email_address)}
                errorMessage={ (isInputValid(quotationData?.schemeDetails?.email_address) === 'error' || !isValidEmail(quotationData?.schemeDetails?.email_address) ) ? 'Please enter a valid email address' :  undefined }
                onPersist={(e) => handleChange(e)}
            />

            <OmFormInputFieldWrapper
                label="Postal code / Physical address"
                id="postal_address"
                name="postal_address"
                labelAlwaysShow={true}
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                isSensitive
                placeholder="e.g. 33 Barlow Street, Accra"
                value={quotationData?.schemeDetails?.postal_address}
                state={isInputValid(quotationData?.schemeDetails?.postal_address)}
                errorMessage={isInputValid(quotationData?.schemeDetails?.postal_address) === 'error' ? 'Please enter your postal code or physical address'  : undefined}
                onPersist={(e) => handleChange(e)}
            />

            <OmFormDropdownFieldWrapper
                id="agentCode"
                name="agentCode"
                useNativeMobileDropdown
                className="inline-field"
                size="full"
                formId="schemeDetailsForm"
                placeholder="Agent Code (Optional)"
                align="left"
                type="text"
                value={quotationData?.schemeDetails?.agentCode}
                options={
                    agents &&
                JSON.stringify(
                    agents.AGENTS.map((option) => ({
                        value: option.name,
                        label: `${option.AgentNoCode} (${option.name})`,
                    }))
                )
                }
                onOnSelect={(e) => updateMVestData({ agentCode: e.detail.value }, 'schemeDetails') }
            />
        
        </div>
    );
};

export default SchemeDetailsSection;
